.card-div {
  width: 15vw;
  min-width: 275px;
  background-size: cover;
  margin: 20px 30px 30px 30px;
  cursor: pointer;
  box-shadow: 0px 0px 48px -2px rgba(185, 185, 185, 0.75);
  transition: transform 100ms ease;
 height: 20%;
 
   border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.card-image {
 text-align:center;
  width: 90%;
  display: inline-block; margin: auto;
  min-height: 160px;
    max-height: 170px;
  height: 18vw;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 7px;
  
}
.card-cont-feat-title{
  white-space: nowrap;
  margin: 2px 6px 2px 2px;
  font-size: 0.7em;
}
.card-image-type {
  align-self: flex-end;
  margin: 10px;
  background-color: rgb(36, 36, 36);
  color: white;
  padding: 2px 6px 6px 6px;
  border-radius: 4px;
}

.card-link {
  text-decoration: none;
  color: unset;
  
}

.card-content-div {
  padding: 30px;
  
}

.card-cont-features {
  margin: 20px 0;
  display: flex;
 
}

.card-cont-feat-p {
  white-space: nowrap;
  margin: 2px 6px 2px 0;
  font-size: 0.8em;
}

.card-cont-feat-icons-div {
  margin-right: 14px;
}

.card-cont-title {
  font-size: 12px;
  font-weight: 500;
}

.card-cont-ubication {
  font-weight: 500;
  margin-top: 10px;
  color: rgb(158, 158, 158);
  font-size: 0.8em;
}

.card-image-counter {
  border-radius: 4px;
  margin: 10px;
  background-color: rgba(97, 97, 97, 0.815);
  color: white;
  padding: 2px 6px 2px 6px;
  font-size: 10px;
}

.card-div:hover {
  transform: scale(1.01);
}

.card-image-icon {
  vertical-align: -2px;
  margin-right: 3px;
}

.card-cont-price-title {
  color: rgb(71, 71, 71);
  
  font-size: 0.8em;
}

.card-cont-price {
  color: rgb(192, 73, 73);
  font-weight: 600;
  font-size: 1.2em;
}

.card-cont-icons {
  color: rgb(59, 59, 59);
  margin-right: 3px;
  font-size: 16px;
  vertical-align: -6px;
  text-align:center;
  align-items:center;
 
}
.cardContact-button-black{
 
  align-self: flex-end;
 margin: 2px;
 background-color: rgb(36, 36, 36);
 color: white;
 padding: 2px 6px 6px 6px;
 border-radius: 4px;
 align-items:right;
}
.image-text-span {
  font-size: 12px;
}

.m2card:after {
  margin-top: 5px;
  font-size: 8px;
}

.card-cont-feat-p {
  color: rgb(90, 90, 90);
  font-size: 12px;
}

.card-currency {
  font-size: 12px;
  font-weight: 400;
  color: gray;
}

.card-null {
  width: 100%;
  background-color: rgb(240, 240, 240);
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-null-loading {
  margin: 0;
}

@media (max-width: 768px) {
  .card-div {
    width: 90%;
  }
  .card-cont-features {
    display: none;
  }
  .card-cont-price-title,
  .card-cont-price {
    text-align: end;
  }
}
@media (max-width: 360px) {
  .card-div {
    width: 70%;
  }
  .card-cont-features {
    display: none;
  }
  .card-cont-price-title,
  .card-cont-price {
    text-align: end;
  }
}

.hc-main {
  width: 100%;
  margin: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
}

.hc-image {
  width: 140px;
  /* height: auto; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  margin: 4px 10px 4px 4px;
}

.hc-title{
  font-size: 0.5em;
  font-weight: 400;
}

.hc-tracks{
  padding: 0 20px;
  align-self: center;
}

.hc-track-item > label{
  margin-right: 4px;
}

.hc-body{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
}

.hc-control{
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
}

/* .hc-control-icons{
  
} */

.hc-control-button{
  background: none;
  border: 1px solid rgb(199, 199, 199);
  margin-top: 5px;
  padding: 2px;
  cursor: pointer;
}

.hc-main-paused{
  border: 1px solid rgb(218, 106, 106);
}