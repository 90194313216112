.register-div {
  width: 100%;
  height: 100vh;
  background-color: rgb(251, 251, 251);
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 20px #e9e9e9;
  padding: 20px 40px;
  min-width: 300px;
  width: 70%;
}

.register-input {
  width: 100%;
  border: 1px solid rgb(184, 184, 184);
  transition: border 200ms ease;
  border-radius: 2px;
  padding: 7px 10px;
  font-size: 14px;
  color: gray;
  margin: 10px;
}

.register-input:focus {
  outline: none;
  border: 1px solid rgb(128, 128, 128);
}
.register-input:invalid {
  outline: none;
  border: 1px solid rgb(255, 0, 0);
  background-color: rgba(255, 0, 0, 0.171);
}

.register-submit {
  padding: 10px 4px;
  width: 100px;
  border: none;
  color: white;
  background-color: #a51a1a;
  border: 1px solid #a51a1a;
  cursor: pointer;

}
.register-submit:focus {
    outline: none;
    color: #a51a1a;
    background-color: white;
}

.register-submit:disabled{
    color: #a51a1a;
    background-color: white;
    cursor: no-drop;
}

.register-title {
  margin: 0px 0 20px;
  font-size: 3em;
  font-weight: 300;
  color: rgb(61, 61, 61);
}
